import { RouteObject, Params } from "react-router-dom";
import { type myRoute } from "../../Pages/PagesConfigs";
import log from './log';
import type { dbMenuItem, burgerMenuItem } from "./../Types";


const localdebug=false

class RouterUtils {

    static getIdFromLocation(param: Params, Id: string): string {
      let result="";
      Object.entries(param).forEach(([paramName, paramValue]) => {
        if (paramName.toString()===Id && paramValue!==undefined) {
          result=paramValue;
        }
      });
      return result ;
    }

    static getSearchParamFromLocation(params: URLSearchParams, Id: string): string {
      let result = (params.has(Id) && params.get(Id) ? params.get(Id)!.toString() :"" );
      return result ;
    }
    
    static setRoutes(config: myRoute, menuitems:  dbMenuItem[] | undefined ) {
      
      let newRoute: RouteObject = {};
      if ((config.path === "/") || (config.path === "*") || (config.path === "**")) {
        //default home and error path
        newRoute.path=config.path;
        newRoute.caseSensitive = config.exact;
        newRoute.Component = config.component;
      } else {
        if (menuitems) {
          let dbRoute = this.dbMenuItemForNavigator(config.navigator, menuitems);
          if (dbRoute) { 
            newRoute.path=config.path;
            newRoute.caseSensitive = config.exact;
            newRoute.Component = config.component;
          }
        }   
      }
      return newRoute;   
    }
    
    static generateRoutesFromConfigs(configs: myRoute[], menuitems:  dbMenuItem[] | undefined, debugprint: boolean ) {
      //this.debugCodeRoutes("generateRoute",configs, debugprint);
      //this.debugDbMenu("generateRoute",menuitems, debugprint);
      let allRoutes: RouteObject[] = [];
      
      configs.forEach((config) => {
        allRoutes = [...allRoutes, this.setRoutes(config, menuitems)];
      });
      
      this.debugRoutes("generateRoute", allRoutes, debugprint||localdebug);
      return allRoutes;
    }        


    static generateMenuFromConfigs(configs: myRoute[], menuitems:  dbMenuItem[] | undefined, debugprint: boolean) {
      //this.debugCodeRoutes("generateMenu", configs, debugprint);
      //this.debugDbMenu("generateMenu",menuitems, debugprint);

      let resultMenuItems: burgerMenuItem[] = [];
      let menuitemsString: string = "";

      if (menuitems) {
        configs.forEach((config) => {
          if (! menuitemsString.includes(config.navigator)) {
              let a = this.menuItemForRoute(config, menuitems);
              if (a) {
                resultMenuItems = [...resultMenuItems, a];
                menuitemsString=menuitemsString + ","+ a.label;
              }
            }
          })
      }
      
      this.debugBurgerMenuItems("generateMenu", resultMenuItems, debugprint||localdebug);
      return resultMenuItems;
    }

    static dbMenuItemForNavigator(nav: string, menuitems:  dbMenuItem[]) : dbMenuItem|undefined {
      for (const item of menuitems) {
        if (item.navigator===nav) {
          return item;
        }
      }
      return undefined;
    }

    static menuItemForRoute(route: myRoute, menuitems: dbMenuItem[]) : burgerMenuItem | undefined {
      let dbitem: dbMenuItem |undefined = this.dbMenuItemForNavigator(route.navigator, menuitems) ;
      if (dbitem!==undefined) {
        return  {id: dbitem.id, link: (route.path===undefined ? "/" : route.path) , label: dbitem.navigator};  
      }
      return undefined;
    }


    static debugRoutes(prefix: string, routes: RouteObject[], debugprint: boolean) {
      routes.forEach((route) => {
        log(debugprint, "RouterUtils","debugRoutes", prefix+": Config: path="  +route.path + ", component=" + (route.Component !== undefined ? "Defined" : "Not Defined") + ", sensitive=" +route.caseSensitive +", id="+ route.id  );
      })
    }

    static debugCodeRoutes(prefix: string, configs: myRoute[], debugprint: boolean) {
      configs.forEach((route) =>  {
        log(debugprint, "RouterUtils","debugConfigRoutes", prefix+": "+ route.pagelocation.toString()+ ": path="  
                        + route.path + ", navigator=" + route.navigator + ", title=" + route.title );
      });
    }

    static debugDbMenu(prefix: string, items: dbMenuItem[] | undefined, debugprint: boolean) {
      if (items!==undefined  && items.length>0 ) {
        items.forEach((item) => {
          log(debugprint, "RouterUtils","debugDbMenu", prefix+": item: id="  +item.id  + ", item=" + item.item  + ", naam=" + item.naam  + ", navigator=" + item.navigator );
        })
      }else {
        log(debugprint, "RouterUtils","debugDbMenu", prefix+": menuitems are undefined");
      }
    }

    static debugBurgerMenuItems(prefix: string, items: burgerMenuItem[] | undefined, debugprint: boolean) {
      if (items!==undefined && items.length>0) {
        items.forEach((item) => {
          log(debugprint, "RouterUtils","debugBurgerMenuItems",prefix+": item: id="  +item.id  + ", label=" + item.label + ", link=" + item.link  + ", class=" + item.className );
        })
      }else {
        log(localdebug, "RouterUtils","debugBurgerMenuItems", prefix+": burgermenuitems are undefined or empty");
      }
    }    
  }
  
  export default RouterUtils;