import { dbMenuItem } from "../Types/RouterMenuTypes";
import { ApiRoutes } from "./ApiRoutes";
import { useGetter, usePost, usePostWithJWT } from "./Query";
import { HttpOptions } from "./../../Common/Types/Global"
import type { dbPrijzen, dbSeizoen, InschrData, Machtiging, Inschrijving, ClubGegeven, newpasswordObject, passwordresetEmail } from "../Types";
import { dbBetaling, dbAfwijkendeBetalingExtras, dbLid } from "../Types";


export const useMenuItems  = (options:HttpOptions, debug: boolean) => useGetter<dbMenuItem[]>(ApiRoutes.menu.menulist, options, debug);

export const useLedenItems  = (options:HttpOptions, debug: boolean) => useGetter<dbLid[]>(ApiRoutes.leden.ledenlist, options, debug);
export const useLid  = (options:HttpOptions, debug: boolean) => useGetter<dbLid[]>(ApiRoutes.leden.lid, options, debug);
export const useUpdateLid = (
    options: HttpOptions, 
    updater?: (oldData: dbLid, newData: dbLid) => dbLid,  
  ) => usePost<dbLid,dbLid>(ApiRoutes.leden.lid, options, updater, ["LedenItems?actieveLeden=1","LedenItems?actieveLeden=0"]);

export const useLedenMachtigingen =  (options:HttpOptions, debug: boolean) => useGetter<InschrData[]>(ApiRoutes.leden.machtigingen, options, debug);
export const useLedenInschrijvingen =  (options:HttpOptions, debug: boolean) => useGetter<InschrData[]>(ApiRoutes.leden.inschrijvingen, options, debug);
export const useLedenClubGegevens =  (options:HttpOptions, debug: boolean) => useGetter<InschrData[]>(ApiRoutes.leden.clubgegevens, options, debug);
export const useUpdateRollen= (
  options: HttpOptions, 
  updater?: (oldData: InschrData, newData: InschrData) => InschrData,
) => usePost<InschrData,InschrData>(ApiRoutes.leden.updateRole, options,undefined,['SettingsMachtigingen','SettingsInschrijvingen','SettingsClubgegevens']);
export const useUpdatePassword=(
  options: HttpOptions,
  updater?: (oldData: newpasswordObject, newData: newpasswordObject) => newpasswordObject,
) => usePostWithJWT<newpasswordObject,newpasswordObject>(ApiRoutes.leden.paswoordreset, options, undefined,[])
export const useSendPasswordResetMail=(
  options: HttpOptions,
  updater?: (oldData: passwordresetEmail, newData: passwordresetEmail) => passwordresetEmail,
) => usePost<passwordresetEmail,passwordresetEmail>(ApiRoutes.leden.paswoordresetmail, options,undefined,[]);

export const useMachtigingItems = (options:HttpOptions, debug: boolean) => useGetter<Machtiging[]>(ApiRoutes.leden.toegang, options, debug);
export const useInschrijvingsItems = (options:HttpOptions, debug: boolean) => useGetter<Inschrijving[]>(ApiRoutes.leden.inschrijving, options, debug);
export const useClubGegevenItems = (options:HttpOptions, debug: boolean) => useGetter<ClubGegeven[]>(ApiRoutes.leden.clubgegeven, options, debug);
export const useSeizoen = (options:HttpOptions, debug: boolean) => useGetter<dbSeizoen[]>(ApiRoutes.settings.seizoen, options, debug);
export const useUpdateSeizoen = (
  options: HttpOptions, 
  updater?: (oldData: dbSeizoen, newData: dbSeizoen) => dbSeizoen,  
) => usePost<dbSeizoen,dbSeizoen>(ApiRoutes.settings.seizoen, options, updater);

export const usePrijzen = (options:HttpOptions, debug: boolean) => useGetter<dbPrijzen[]>(ApiRoutes.settings.prijzen, options, debug);
export const useUpdatePrijzen = ( 
  options:HttpOptions, 
  updater?: (oldData: dbPrijzen, newData: dbPrijzen) => dbPrijzen
) => usePost<dbPrijzen,dbPrijzen>(ApiRoutes.settings.prijzen, options, undefined,["Prijzen"]);
export const useBetalingOpenstaandeItems = (options:HttpOptions, debug: boolean) => useGetter<dbBetaling[]>(ApiRoutes.betalingen.openstaande, options, debug);
export const useBetalingAfwijkendeItems = (options:HttpOptions, debug: boolean) => useGetter<dbAfwijkendeBetalingExtras[]>(ApiRoutes.betalingen.afwijkende, options, debug);
export const useBetalingItems = (options:HttpOptions, debug: boolean) => useGetter<dbBetaling[]>(ApiRoutes.betalingen.lijst, options, debug);
export const useUpdateBetaling = (
  options: HttpOptions, 
  updater?: (oldData: dbBetaling, newData: dbBetaling) => dbBetaling,
) => usePost<dbBetaling,dbBetaling>(ApiRoutes.betalingen.lid, options,undefined,["Betalingen_Betalingen"]);