export interface SnttcStateContext {
    webdebug: boolean;
    authenticated: boolean;
}

export enum ActionType {
    DEBUG_ON = 'DEBUG_ON',
    DEBUG_OFF = 'DEBUG_OFF',
    AUTHENTICATED = 'AUTHENTICATED',
    NOTAUTHENTICATED ='NOTAUTHENTICATED',
    UPDATE_CONTAINERHEIGHT = 'UPDATE_CONTAINERHEIGHT',
    UPDATE_CONTAINERWIDTH = 'UPDATE_CONTAINERWIDTH'    
}

export type Action = { type: ActionType.DEBUG_ON } | { type: ActionType.DEBUG_OFF }  | 
                        { type: ActionType.AUTHENTICATED } |
                        { type: ActionType.NOTAUTHENTICATED } |
                        { type: ActionType.UPDATE_CONTAINERHEIGHT; height: number } |
                        { type: ActionType.UPDATE_CONTAINERWIDTH; width: number} ;

export interface HttpOptions {
    queryKey?: string
    pathparams?: object
    queryparams?: object;
    debug: boolean;
    body?: unknown;
    enabled?: boolean;
    jwt?: string;
}


export type containerDimensions = {
    containerHeigth: number,
    containerWidth: number
}

export type isloading = {
    isLoading: boolean
}