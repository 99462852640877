import { api } from './Api';
import log from '../../Common/Utils/log';
import { softLogoutLogin } from './Authentication';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HttpOptions } from '../../Common/Types/Global'
import axios, { AxiosResponse, AxiosError } from "axios";
import toast from "react-hot-toast";

const localLogOverrule= false;

function parsePath(path: string, pathparams: Object|undefined, debug: boolean): string {
    if (pathparams===undefined) { return path;}
    let result=path;
    Object.keys(pathparams).forEach(key =>
        result = result.replace(':'+key, pathparams[key as keyof typeof pathparams].toString())
    )
    log(localLogOverrule||debug,"Query.ts","parsePath","path with pathvariables replaced: "+result);
    return result;
}

function parseParams (parmObj : Object|undefined, debug: boolean): Object|undefined {
    if (parmObj===undefined) {
        if (debug===false) { return undefined; }
        else parmObj= { 'debug' : 1 };
    } else {
        if (debug) {
            Object.assign(parmObj, { 'debug' : 1})
        }
    } 
    return parmObj;
}

function imitatePathString(options:HttpOptions): string {
    let result ="" ;
    if (options.pathparams!==undefined) {
        Object.keys(options.pathparams).forEach(key =>
            result = result + (key+"="+  options.pathparams![key as keyof typeof options.pathparams] )
        )    
    }
    return result;
}

function imitateQueryString(options:HttpOptions): string {
    let params:string[] = [];
    if(options.queryparams!==undefined) {
        let obj: Object = options.queryparams;  
        Object.keys(obj).forEach(key =>
            params.push((key+"="+obj[key as keyof typeof obj].toString()) )   
        )
    }
    if (options.debug) { params.push("debug=1") }
    return (params.length>0? '?' + params.join('&'):'');
}

export function makeQueryKeyString(path:string, options:HttpOptions): string {
    let result="";;
    if (options.queryKey && options.queryKey.length>0) {result=options.queryKey} else {result=path.replaceAll('/','_') }
    result= result + (imitatePathString(options).length > 0 ? "_" + imitatePathString(options) : '');
    result = result + imitateQueryString(options);
    return result;
}

export const getter = async<T> (path: string, options:HttpOptions, debug: boolean) => {
    let queryPath=parsePath(path,options.pathparams, options.debug);
    let queryParams=parseParams(options.queryparams, options.debug);
    log(localLogOverrule||debug,"Query.ts","getter","queryPath data: " , queryPath );
    log(localLogOverrule||debug,"Query.ts","getter","queryParams data: " , queryParams );
    const { data, status } = await api.get<T>(queryPath, queryParams ? {params: queryParams} : undefined );
    if (status===401) { 
        log(localLogOverrule||debug,"Query.ts","getter","GET: 401 received, going to softlogout (and login)");
        softLogoutLogin(); 
    }
    log(localLogOverrule||debug,"Query.ts","getter","returning data: " , data );
    return data as T;
}

export const useGetter = <T>(path: string, options: HttpOptions, debug: boolean) => {
    log(localLogOverrule||debug,"Query.ts","useGetter","path:" +path + ", options ", options);
    log(localLogOverrule||debug,"Query.ts","useGetter","querykeyString ", makeQueryKeyString(path,options) );
    
    return useQuery({
        queryKey: [makeQueryKeyString(path, options)],
        queryFn: ()=> getter(path, options, debug) as T,
        enabled: options?.enabled ? options.enabled: true 
    });
};



export const usePost = <T, S>(
    url: string,
    options: HttpOptions,
    updater?: (oldData: T, newData: S) => T,
    additionalInvalidatePaths?: string[],
  ) => {
    log(localLogOverrule,"Query.ts","usePost","path:" +url);
    log(localLogOverrule,"Query.ts","usePost","options:", options);
    log(localLogOverrule,"Query.ts","usePost","updater:", updater);
    log(localLogOverrule,"Query.ts","usePost","additional paths:", additionalInvalidatePaths);
    log(localLogOverrule,"Query.ts","usePost","options:", makeQueryKeyString(url,options) );

    let queryPath=parsePath(url,options.pathparams, options.debug);

    return useGenericMutation<T, S>(
        (data) => api.post<S>(queryPath, data),
        url,
        options,
        updater,
        additionalInvalidatePaths
        ); 
  };

export const usePostWithJWT = <T, S>(
    url: string,
    options: HttpOptions,
    updater?: (oldData: T, newData: S) => T,
    additionalInvalidatePaths?: string[],
  ) => {
    log(localLogOverrule,"Query.ts","usePost","path:" +url);
    log(localLogOverrule,"Query.ts","usePost","options:", options);
    log(localLogOverrule,"Query.ts","usePost","updater:", updater);
    log(localLogOverrule,"Query.ts","usePost","additional paths:", additionalInvalidatePaths);
    log(localLogOverrule,"Query.ts","usePost","options:", makeQueryKeyString(url,options) );

    let queryPath=parsePath(url,options.pathparams, options.debug);

    return useGenericMutation<T, S>(
        (data) => api.postWithJwt<S>(queryPath, options.jwt!, data),
        url,
        options,
        updater,
        additionalInvalidatePaths
        );
  };


const useGenericMutation = <T, S>(
    func: (data: T | S) => Promise<AxiosResponse<S>>,
    url: string,
    options: HttpOptions,
    updater?: ((oldData: T, newData: S) => T) | undefined,
    additionalInvalidatePaths?: string[],
  ) => {
    const queryClient = useQueryClient();
  
    return useMutation<AxiosResponse, AxiosError, T | S, unknown>({
      mutationFn: func, 

      onMutate: async (data: T | S) => {
        //await queryClient.cancelQueries([url!, params]);
        await queryClient.cancelQueries({ queryKey: [makeQueryKeyString(url, options)]});
  
        const previousData = queryClient.getQueryData([makeQueryKeyString(url, options)]);
  
        queryClient.setQueryData<T>([url!, options], (oldData) => {
          return updater ? updater(oldData!, data as S) : (data as T);
        });
  
        return previousData;
      },
      onError: (err: any, _: any, context: any) => {
        if (axios.isAxiosError(err)) {
            toast.error("(Q)(GM)(1): " + err.message);
        } else if (err instanceof Error) {
            toast.error("(Q)(GM)(2): " + err.message);
        } else {
            toast.error("(Q)(GM)(3): " + err.toString)
        }
      },      
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [makeQueryKeyString(url, options)]});
        if (additionalInvalidatePaths && additionalInvalidatePaths.length>0) {
          //additionalInvalidatePaths.forEach ((s) => queryClient.invalidateQueries({ queryKey: [s] }))
          additionalInvalidatePaths.forEach ((s) => queryClient.refetchQueries({ queryKey: [s] }))
        }        
      },
  });
};